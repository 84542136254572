import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  AddPlacementLearningModuleCaseLogRequest,
  DaySchedule,
  GetPlacementApplicationDocumentsDocument,
  GetPlacementOnboardingDocumentsDocument,
  GetPlacementResponse,
  GetPublishedOpportunityOpportunityDetails,
  GetPublishedOpportunitySeatsAvailabilityCalculationType,
  UpdatePlacementLearningModuleCaseLogRequest,
} from "@platform-app/app/core/api/models";
import {
  ExterniFeedbacksService,
  PlacementsService,
  PublishedOpportunityService,
} from "@platform-app/app/core/api/services";
import { DateUtility } from "@platform-app/app/main/shared/utilities/date.utility";
import { finalize, Subject, switchMap, takeUntil, tap } from "rxjs";

export interface PlacementStateModel {
  placement: GetPlacementResponse | null;
  program: GetPublishedOpportunityOpportunityDetails | null;
  applicationId: string | null;
  applicationDocuments: GetPlacementApplicationDocumentsDocument[] | null;
  onboardingDocuments: GetPlacementOnboardingDocumentsDocument[] | null;
  externiFeedbackExists: boolean | null;
  dataLoading: boolean;
  completeStageLoading: boolean;
}

export class LoadPlacement {
  static readonly type = "[Placement page] Load Placement";
  constructor(public payload: { id: string }) {}
}

export class UpdatePlacementSchedule {
  static readonly type = "[Placement page] Update Placement Schedule";
  constructor(
    public payload: { daySchedules: DaySchedule[]; timeZoneId: number },
  ) {}
}

export class AddPlacementModuleCaseLog {
  static readonly type = "[Placement page] Add Placement Module Case Log";
  constructor(
    public payload: {
      moduleId: string;
      body: AddPlacementLearningModuleCaseLogRequest;
    },
  ) {}
}

export class UpdatePlacementModuleCaseLog {
  static readonly type = "[Placement page] Update Placement Module Case Log";
  constructor(
    public payload: {
      moduleId: string;
      caseLogId: string;
      body: UpdatePlacementLearningModuleCaseLogRequest;
    },
  ) {}
}

export class DeletePlacementModuleCaseLog {
  static readonly type = "[Placement page] Delete Placement Module Case Log";
  constructor(
    public payload: {
      moduleId: string;
      caseLogId: string;
    },
  ) {}
}

export class CompleteOnboardingStage {
  static readonly type = "[Placement page] Complete Placement Onboarding Stage";
}

export class CompleteActiveStage {
  static readonly type = "[Placement page] Complete Placement Active Stage";
}

export class CompleteEvaluationStage {
  static readonly type = "[Placement page] Complete Placement Evaluation Stage";
}

export class SetOpportunityDetails {
  static readonly type = "[Placement page] Set Opportunity Details";
  constructor(public payload: { programId: string }) {}
}

export class SetIsExterniFeedbackExist {
  static readonly type = "[Placement page] Set Externi Feedback Existence";
}

export class SendExterniFeedback {
  static readonly type = "[Placement page] Send Externi Feedback";
  constructor(
    public payload: { comment: string | null; rate: number | null },
  ) {}
}

export class ResetExterniFeedback {
  static readonly type = "[Placement page] Reset Externi Feedback";
}

export class LoadApplicationDocuments {
  static readonly type = "[Placement page] Load Application Documents";
}

export class LoadOnboardingDocuments {
  static readonly type = "[Placement page] Load Onboarding Documents";
}

@State<PlacementStateModel>({
  name: "Placement",
  defaults: {
    placement: null,
    program: null,
    applicationId: null,
    applicationDocuments: null,
    onboardingDocuments: null,
    externiFeedbackExists: null,
    dataLoading: false,
    completeStageLoading: false,
  },
})
@Injectable()
export class PlacementState {
  private cancelPrevious$ = new Subject<void>();

  constructor(
    private placementsService: PlacementsService,
    private publishedOpportunityService: PublishedOpportunityService,
    private externiFeedbacksService: ExterniFeedbacksService,
  ) {}

  @Selector()
  static placement(state: PlacementStateModel) {
    return state.placement;
  }

  @Selector()
  static program(state: PlacementStateModel) {
    return state.program;
  }

  @Selector()
  static applicationId(state: PlacementStateModel) {
    return state.applicationId;
  }

  @Selector()
  static applicationDocuments(state: PlacementStateModel) {
    return state.applicationDocuments;
  }

  @Selector()
  static onboardingDocuments(state: PlacementStateModel) {
    return state.onboardingDocuments;
  }

  @Selector()
  static externiFeedbackExists(state: PlacementStateModel) {
    return state.externiFeedbackExists;
  }

  @Selector()
  static dataLoading(state: PlacementStateModel) {
    return state.dataLoading;
  }

  @Selector()
  static completeStageLoading(state: PlacementStateModel) {
    return state.completeStageLoading;
  }

  @Action(LoadPlacement)
  loadPlacement(ctx: StateContext<PlacementStateModel>, action: LoadPlacement) {
    return this.getPlacement(ctx, action.payload.id);
  }

  @Action(UpdatePlacementSchedule)
  updatePlacementSchedule(
    ctx: StateContext<PlacementStateModel>,
    action: UpdatePlacementSchedule,
  ) {
    const { daySchedules, timeZoneId } = action.payload;
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    ctx.patchState({ dataLoading: true });

    return this.placementsService
      .placementsIdSchedulePut({
        id: placement.id,
        body: {
          daySchedules: daySchedules,
          timeZoneId: timeZoneId,
        },
      })
      .pipe(switchMap(() => this.getPlacement(ctx, placement.id)));
  }

  @Action(AddPlacementModuleCaseLog)
  addPlacementModuleCaseLog(
    ctx: StateContext<PlacementStateModel>,
    action: AddPlacementModuleCaseLog,
  ) {
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    return this.placementsService
      .placementsIdLearningModulesModuleIdCaseLogsPost({
        id: placement.id,
        moduleId: action.payload.moduleId,
        body: action.payload.body,
      })
      .pipe(switchMap(() => this.getPlacement(ctx, placement.id)));
  }

  @Action(DeletePlacementModuleCaseLog)
  deletePlacementModuleCaseLog(
    ctx: StateContext<PlacementStateModel>,
    action: DeletePlacementModuleCaseLog,
  ) {
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    return this.placementsService
      .placementsIdLearningModulesModuleIdCaseLogsCaseLogIdDelete({
        id: placement.id,
        moduleId: action.payload.moduleId,
        caseLogId: action.payload.caseLogId,
      })
      .pipe(switchMap(() => this.getPlacement(ctx, placement.id)));
  }

  @Action(UpdatePlacementModuleCaseLog)
  updatePlacementModuleCaseLog(
    ctx: StateContext<PlacementStateModel>,
    action: UpdatePlacementModuleCaseLog,
  ) {
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    return this.placementsService
      .placementsIdLearningModulesModuleIdCaseLogsCaseLogIdPut({
        id: placement.id,
        moduleId: action.payload.moduleId,
        caseLogId: action.payload.caseLogId,
        body: action.payload.body,
      })
      .pipe(switchMap(() => this.getPlacement(ctx, placement.id)));
  }

  @Action(CompleteOnboardingStage)
  completeOnboardingStage(ctx: StateContext<PlacementStateModel>) {
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    ctx.patchState({ completeStageLoading: true });

    return this.placementsService
      .placementsIdCompleteOnboardingPut({
        id: placement.id,
      })
      .pipe(
        switchMap(() => this.getPlacement(ctx, placement.id)),
        finalize(() => ctx.patchState({ completeStageLoading: false })),
      );
  }

  @Action(CompleteActiveStage)
  completeActiveStage(ctx: StateContext<PlacementStateModel>) {
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    ctx.patchState({ completeStageLoading: true });

    return this.placementsService
      .placementsIdCompleteActivePut({
        id: placement.id,
      })
      .pipe(
        switchMap(() => this.getPlacement(ctx, placement.id)),
        finalize(() => ctx.patchState({ completeStageLoading: false })),
      );
  }

  @Action(CompleteEvaluationStage)
  completeEvaluationStage(ctx: StateContext<PlacementStateModel>) {
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    ctx.patchState({ completeStageLoading: true });

    return this.placementsService
      .placementsIdCompleteEvaluationPut({
        id: placement.id,
      })
      .pipe(
        switchMap(() => this.getPlacement(ctx, placement.id)),
        finalize(() => ctx.patchState({ completeStageLoading: false })),
      );
  }

  @Action(LoadApplicationDocuments)
  loadApplicationDocuments(ctx: StateContext<PlacementStateModel>) {
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    return this.placementsService
      .placementsIdApplicationDocumentsGet({
        id: placement.id,
      })
      .pipe(
        tap((result) => {
          ctx.patchState({
            applicationId: result.applicationId,
            applicationDocuments: result.documents,
          });
        }),
        takeUntil(this.cancelPrevious$),
      );
  }

  @Action(LoadOnboardingDocuments)
  loadOnboardingDocuments(ctx: StateContext<PlacementStateModel>) {
    const placement = ctx.getState().placement;

    if (!placement) {
      return;
    }

    return this.placementsService
      .placementsIdOnboardingDocumentsGet({
        id: placement.id,
      })
      .pipe(
        tap((result) => {
          ctx.patchState({
            onboardingDocuments: result,
          });
        }),
        takeUntil(this.cancelPrevious$),
      );
  }

  @Action(SetOpportunityDetails)
  setOpportunityDetails(
    ctx: StateContext<PlacementStateModel>,
    action: SetOpportunityDetails,
  ) {
    ctx.patchState({
      dataLoading: true,
    });

    return this.publishedOpportunityService
      .getPublishedOpportunity({
        opportunityId: action.payload.programId,
        body: {
          seatsCalculationType:
            GetPublishedOpportunitySeatsAvailabilityCalculationType.ProvideDates,
          studentId: null,
          seatsAvailabilityStartDate: DateUtility.convertToDateOnlyString(
            new Date(),
          ),
          seatsAvailabilityEndDate: DateUtility.convertToDateOnlyString(
            new Date(),
          ),
        },
      })
      .pipe(
        tap((result) => {
          ctx.patchState({
            program: result,
          });
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }

  @Action(SetIsExterniFeedbackExist) setIsExterniFeedbackExist(
    ctx: StateContext<PlacementStateModel>,
  ) {
    ctx.patchState({
      dataLoading: true,
    });

    return this.externiFeedbacksService.externiFeedbacksUserFeedbackGet().pipe(
      tap((response) => {
        ctx.patchState({
          externiFeedbackExists: response.feedbackExists,
        });
      }),
      takeUntil(this.cancelPrevious$),
      finalize(() =>
        ctx.patchState({
          dataLoading: false,
        }),
      ),
    );
  }

  @Action(SendExterniFeedback) sendExterniFeedback(
    ctx: StateContext<PlacementStateModel>,
    action: SendExterniFeedback,
  ) {
    ctx.patchState({
      dataLoading: true,
    });

    return this.externiFeedbacksService
      .externiFeedbacksUserFeedbackPost({ body: action.payload })
      .pipe(
        tap((response) => {
          ctx.patchState({
            externiFeedbackExists: !!response.feedbackId,
          });
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() =>
          ctx.patchState({
            dataLoading: false,
          }),
        ),
      );
  }

  @Action(ResetExterniFeedback) resetExterniFeedback(
    ctx: StateContext<PlacementStateModel>,
  ) {
    ctx.patchState({
      externiFeedbackExists: null,
    });
  }

  getPlacement(ctx: StateContext<PlacementStateModel>, id: string) {
    ctx.patchState({ dataLoading: true });

    return this.placementsService.placementsIdGet({ id }).pipe(
      tap((response) => {
        ctx.patchState({
          placement: response,
        });
      }),
      takeUntil(this.cancelPrevious$),
      finalize(() =>
        ctx.patchState({
          dataLoading: false,
        }),
      ),
    );
  }
}
